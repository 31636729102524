import { TZDate } from "@date-fns/tz";
import { format as formatDate, parse as parseDate } from "date-fns";

/**
 * 追跡番号をハイフン付きにフォーマットして返します。
 * @param {string} trackingNumber 追跡番号
 * @returns {string} 4桁ごとにハイフン(-)で区切られた追跡番号
 */
export function formatTrackingNumber(trackingNumber) {
  return trackingNumber.replace(/([0-9]{4})([0-9]{4})([0-9]{4})/, "$1-$2-$3");
}

/**
 * 時間のフォーマット変換を行う。
 * @param {string} time
 * @returns {string}
 */
export function desiredTimeFormat(time) {
  if (!time) {
    return "";
  }
  return `${time.slice(0, 2).replace(/^0/, "")}～${time
    .slice(2, 4)
    .replace(/^0/, "")}時`;
}

/**
 * 日付文字列を指定の書式でフォーマットする。
 * @param {string} date 日付文字列
 * @param {string} formatStr date-fnsのフォーマット書式
 * @param {import("date-fns").FormatOptions} [options] date-fnsのフォーマットオプション
 * @returns {string} フォーマット済の日付文字列
 */
export function formatStringDate(date, formatStr, options) {
  /** @type {Date} */
  let parsedDate;
  if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(date)) {
    parsedDate = parseDate(date, "yyyy-MM-dd HH:mm:ss", new Date());
  } else if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(date)) {
    parsedDate = parseDate(date, "yyyy-MM-dd HH:mm", new Date());
  } else if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
    parsedDate = parseDate(date, "yyyy-MM-dd", new Date());
  }
  return formatDate(parsedDate ?? date, formatStr, options);
}

/**
 * 郵便番号のフォーマット変換を行う。
 * @param {string} postcode
 * @returns {string}
 */
export function displayPostcodeFormat(postcode) {
  let startCode = postcode.slice(0, 3);
  let endCode = postcode.slice(3);
  let formattedPostcode = startCode + "-" + endCode;
  return formattedPostcode;
}

/**
 * UTCの日付文字列を日本標準時JSTの指定書式でフォーマットする。
 * @param {string} date UTC(基本形式)の日付文字列
 * @param {string} formatStr date-fnsのフォーマット書式
 * @param {import("date-fns").FormatOptions} [options] date-fnsのフォーマットオプション
 * @returns {string} フォーマット済の日付文字列
 */
export function formatUtcToJst(date, formatStr, options) {
  const dateObj = new TZDate(convertToUtcExtendedFormat(date), "Asia/Tokyo");
  return formatDate(dateObj, formatStr, options);
}

/**
 * 日本標準時での現在時刻を取得する。
 * @returns {Date} 現在時刻（日本標準時）
 */
export function getCurrentDateTimeOnJst() {
  const currentUtc = new Date().toISOString();
  const dateObj = new TZDate(currentUtc, "Asia/Tokyo");
  return dateObj;
}

/**
 * ISO8601(UTC)の基本形式文字列を拡張形式文字列に変換する
 * @param {string} date
 * @returns {string} 拡張形式の日付文字列
 */
function convertToUtcExtendedFormat(date) {
  return (
    date.slice(0, 4) +
    "-" +
    date.slice(4, 6) +
    "-" +
    date.slice(6, 11) +
    ":" +
    date.slice(11, 13) +
    ":" +
    date.slice(13, 16)
  );
}

/**
 * 英数字を半角に変換する
 * @param {string} value
 * @returns {string}
 */
export function toHalfWidth(value) {
  return value.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
}

/**
 * 配送センターの名前を取得する
 * @param {Array<import("~/libs/commonTypes").DepotLocation>} locationList
 * @param {number} locationId
 * @returns {string}
 */
export function getLocationName(locationList, locationId) {
  return locationList
    .map((location) => {
      const center = location.centers.find(
        (center) => center.id === locationId,
      );
      return center ? center.name : "";
    })
    .join("");
}
