<script>
  import sleep from "sleep-promise";
  import { getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import backendApi from "~/libs/backendApi";
  import {
    CONTEXT_KEY_USER,
    DeliveryRecordTypes,
    driverConditionType,
  } from "~/libs/constants";
  import depotLocations from "~/libs/depotLocations";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";
  import CourierActivityTable from "~/pages/Activity/CourierActivityTable.svelte";
  import RouteMap from "~/pages/Activity/RouteMap.svelte";
  import TruckerActivityTable from "~/pages/Activity/TruckerActivityTable.svelte";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /**
   * マップコンポーネント
   */
  let routeMap;
  /**
   * 宅配ドライバーの稼働実績テーブルのコンポーネント
   */
  let courierActivityTable;
  /**
   * 幹線輸送ドライバーの稼働実績テーブルのコンポーネント
   */
  let truckerActivityTable;
  /**
   * @type {string}
   * 結果一覧エリアの表示状態
   */
  let resultAreaDisplay = "block";
  /**
   * @type {string}
   * データ取得失敗コメントの表示状態
   */
  let failedDataCommentDisplay = "none";
  /**
   * @type {Array<import("~/libs/commonTypes").DriverActivity>}
   * ドライバー稼働状況一覧取得APIの実行結果を格納する変数
   */
  let results = [];
  /**
   * 選択された検索条件を格納する変数
   * - 0: 幹線輸送ドライバー
   * - 1: 宅配ドライバー
   * @type {import("~/libs/constants").driverConditionType}
   */
  let selectConditions = driverConditionType.COURIER;

  /** @type {Array<import("~/libs/commonTypes").DriverActivity>} 宅配ドライバーの稼働状況リスト */
  let courierResults = [];

  /** @type {Array<import("~/libs/commonTypes").DriverActivity>} 幹線輸送ドライバーの稼働状況リスト */
  let truckerResults = [];

  /** @type {number} 現在選択中のドライバーIndex */
  let currentDriverIndex;

  /** @type {Array<{id: number, name: string, locationShortName: string, latitude: number, longitude: number, type: number, companyId: number}>} 配送センター一覧を格納する配列 */
  let locationList = [];

  /** @type {Map<number, object>} センターIDをキーとしたセンター情報のマップ*/
  let centersMap;

  /** @type {boolean} 確認状態を保存するボタンの活性非活性 */
  let disabledUpdateButton = true;

  (async () => {
    // 配送センター一覧の取得
    const allLocationList = await depotLocations.get();
    for (let i = 0; i < allLocationList.length; i++) {
      for (let j = 0; j < allLocationList[i].centers.length; j++) {
        // トランプ以外の宅配パートナー管理者の場合、配送センターを自身の管轄のものに絞り込む
        if (
          userContext.hasShippingPartnerAdminRole() &&
          !userContext.hasTrumpAdminRole() &&
          allLocationList[i].centers[j].companyId !==
            userContext.loginUser?.companyId
        ) {
          continue;
        }
        locationList.push(allLocationList[i].centers[j]);
      }
    }

    // 配送センター情報のマップを取得
    centersMap = await depotLocations.getCentersMap();
  })();

  async function initialize() {
    // リロード前のドライバーIDを保持
    const beforeReloadDriverId =
      selectConditions === driverConditionType.COURIER
        ? courierResults[currentDriverIndex - 1]?.userId
        : truckerResults[currentDriverIndex - 1]?.userId;

    // 初期化処理
    currentDriverIndex = null;
    if (routeMap) routeMap = null;
    if (courierActivityTable) courierActivityTable = null;
    if (truckerActivityTable) truckerActivityTable = null;
    await execDriverActivityApi();
    await initDriverActivity();
    routeMap = RouteMap;
    courierActivityTable = CourierActivityTable;
    truckerActivityTable = TruckerActivityTable;

    // リロード前のドライバーIDが設定されている場合、そのドライバーの番号を設定
    if (beforeReloadDriverId) {
      // マップが生成されるまで待機
      await sleep(100);
      currentDriverIndex =
        selectConditions === driverConditionType.COURIER
          ? courierResults.findIndex(
              (result) => result.userId === beforeReloadDriverId,
            ) + 1
          : truckerResults.findIndex(
              (result) => result.userId === beforeReloadDriverId,
            ) + 1;
    }
  }

  /**
   * ドライバー稼働状況一覧取得APIを実行する。
   */
  async function execDriverActivityApi() {
    try {
      results = (await backendApi.getDriverActivites()) ?? [];
      failedDataCommentDisplay = "none";
      resultAreaDisplay = "block";
    } catch (error) {
      console.error(error);
      failedDataCommentDisplay = "block";
      resultAreaDisplay = "none";
    }
  }

  /**
   * ドライバー稼働状況を初期化する。
   */
  async function initDriverActivity() {
    // resultsを、driverTypeごとに2つの配列に分割
    courierResults = results.filter(
      (result) =>
        result.driverType === driverConditionType.COURIER && result.workTimes,
    );
    truckerResults = results.filter(
      (result) =>
        result.driverType === driverConditionType.TRUCKER && result.workTimes,
    );

    for (let i = 0; i < courierResults.length; i++) {
      // ドライバー番号を設定
      courierResults[i]["index"] = i + 1;
      let undeliveredList =
        courierResults[i].results?.undeliveredList?.map(
          (item) => item.trackingNumber,
        ) ?? [];
      let deliveredList = new Set();
      let undeliverableList = new Set();

      // 配送記録から持出し・配達完了・持ち戻り完了時間を取得
      for (const record of courierResults[i].results?.deliveryRecords ?? []) {
        if (record.recordType === DeliveryRecordTypes.DELIVERED) {
          deliveredList.add(record.trackingNumber);
          if (undeliveredList.includes(record.trackingNumber)) {
            // 配達完了済みの荷物は未配達リストから削除
            undeliveredList = undeliveredList.filter(
              (item) => item !== record.trackingNumber,
            );
          }
          if (undeliverableList.has(record.trackingNumber)) {
            // 配達完了済みの荷物は未配達リストから削除
            undeliverableList.delete(record.trackingNumber);
          }
        } else if (
          record.recordType === DeliveryRecordTypes.UNDELIVERABLE ||
          record.recordType === DeliveryRecordTypes.UNDELIVERABLE_NO_MOVE
        ) {
          undeliverableList.add(record.trackingNumber);
          if (undeliveredList.includes(record.trackingNumber)) {
            // 配達不可登録済みの荷物は未配達リストから削除
            undeliveredList = undeliveredList.filter(
              (item) => item !== record.trackingNumber,
            );
          }
        }

        if (
          record.recordType === DeliveryRecordTypes.OUT_FOR_DELIVERY &&
          !courierResults[i].firstOutForDeliveryAt
        ) {
          // レコードタイプが持出し、かつ初回持出し時間が設定されていない場合
          courierResults[i].firstOutForDeliveryAt = record.dateTime;
        } else if (record.recordType === DeliveryRecordTypes.ALL_DELIVERED) {
          // レコードタイプが全て配達完了の場合
          courierResults[i].lastDeliveredAt = record.dateTime;
          courierResults[i].lastTakebackAt = undefined;
        } else if (record.recordType === DeliveryRecordTypes.ALL_TAKEBACK) {
          // レコードタイプが全て持ち戻り完了の場合
          courierResults[i].lastTakebackAt = record.dateTime;
          courierResults[i].lastDeliveredAt = undefined;
        }
      }
      courierResults[i].deliveryRecordsCount = {
        undelivered: undeliveredList.length,
        delivered: deliveredList.size,
        undeliverable: undeliverableList.size,
      };
      console.log(
        courierResults[i].userDisplayName,
        courierResults[i].deliveryRecordsCount,
      );
    }
    for (let i = 0; i < truckerResults.length; i++) {
      // ドライバー番号を設定
      truckerResults[i]["index"] = i + 1;

      // 配達先の中継配送センターのデータを集計
      /** @type {Array<number>} */
      const transportSourceIdList = [];
      /** @type {Array<{transportDestivationId: number, packageNum: number}>} */
      const transportDestivationIdAndPackageNumList = [];

      for (
        let j = 0;
        j < (truckerResults[i].results?.inTransitDeliveryList?.length ?? 0);
        j++
      ) {
        const inTransitDelivery =
          truckerResults[i].results.inTransitDeliveryList[j];
        transportSourceIdList.push(inTransitDelivery.transportSourceId);

        for (let k = 0; k < inTransitDelivery.deliveryInfoList.length; k++) {
          const deliveryInfo = inTransitDelivery.deliveryInfoList[k];
          let index = transportDestivationIdAndPackageNumList.findIndex((e) => {
            return (
              e.transportDestivationId === deliveryInfo.transportDestivationId
            );
          });
          if (index !== -1) {
            // 配送先の中継配送センターのデータがすでに配列に入っている場合
            transportDestivationIdAndPackageNumList[index].packageNum +=
              deliveryInfo.trackingNumberList.length;
          } else {
            transportDestivationIdAndPackageNumList.push({
              transportDestivationId: deliveryInfo.transportDestivationId,
              packageNum: deliveryInfo.trackingNumberList.length,
            });
          }
        }
      }

      truckerResults[i]["transportSourceIdList"] = transportSourceIdList;
      truckerResults[i]["transportDestivationIdAndPackageNumList"] =
        transportDestivationIdAndPackageNumList;
    }
  }

  // ページの初期化処理（非同期）
  loadingProgress.wrapAsync(async () => {
    await initialize();
  })();

  /**
   * ドライバー稼働状況の管理者確認フラグを更新する
   */
  async function updateDriverActivityCheck() {
    try {
      /** @type {Array<import("~/libs/backendApi").DriverActivityCheckInfo>}*/
      const requestData = [];
      for (const result of courierResults) {
        requestData.push({
          userId: result.userId,
          driverType: driverConditionType.COURIER,
          checked: result.checked,
        });
      }
      for (const result of truckerResults) {
        requestData.push({
          userId: result.userId,
          driverType: driverConditionType.TRUCKER,
          checked: result.checked,
        });
      }
      await backendApi.updateDriverActivityCheck({ updates: requestData });
      disabledUpdateButton = true;
    } catch (error) {
      console.error(error);
      toast.error($_("errors.updateDefaultMessage.message"));
    }
  }
</script>

<div class="wrapper">
  <!-- タイトル -->
  <div class="titleArea">
    <div class="titleLine" />
    <h1 class="title">配送状況の確認</h1>
  </div>

  <div class="infoWrapper">
    <!-- 現在地マップ -->
    <div class="businessArea">
      <div class="captionArea">現在地マップ</div>
      <div class="mapArea" style="display: {resultAreaDisplay};">
        <svelte:component
          this={routeMap}
          {selectConditions}
          {courierResults}
          {truckerResults}
          bind:currentDriverIndex
          {locationList}
          {centersMap}
        />
        <div class="noteOnReload">
          マップ右上の<span class="material-icons md-24">refresh</span
          >ボタンを押すと<br />
          最新の配送状況へ更新されます
        </div>
        <button
          class="reloadButton"
          on:click={() => {
            loadingProgress.wrapAsync(async () => {
              await initialize();
            })();
          }}
        >
          <p class="material-icons md-24">refresh</p>
        </button>
      </div>
      <div id="failedDataComment" style="display: {failedDataCommentDisplay};">
        データ取得に失敗しました。
      </div>
    </div>

    <!-- ドライバー一覧 -->
    <div class="businessArea">
      <!-- ドライバー種別を選択 -->
      <div class="captionTabs">
        <input
          type="radio"
          name="selectConditions"
          id="allData"
          bind:group={selectConditions}
          on:change={() => {
            currentDriverIndex = null;
          }}
          value={driverConditionType.COURIER}
          checked
        />
        <label for="allData" class="selectConditions"> 宅配ドライバー</label>
        <input
          type="radio"
          name="selectConditions"
          id="monthSelection"
          bind:group={selectConditions}
          on:change={() => {
            currentDriverIndex = null;
          }}
          value={driverConditionType.TRUCKER}
        />
        <label for="monthSelection" class="selectConditions"
          >幹線輸送ドライバー</label
        >
      </div>

      <!-- 結果一覧 -->
      <div class="resultArea" style="display: {resultAreaDisplay};">
        <div id="datatable">
          {#if !courierActivityTable && !truckerActivityTable}
            <div class="loadingMessage">データ取得中</div>
          {/if}
          {#if selectConditions === driverConditionType.COURIER}
            <svelte:component
              this={courierActivityTable}
              bind:results={courierResults}
              bind:currentDriverIndex
              bind:disabledUpdateButton
              {centersMap}
              on:updateDriverActivityCheck={updateDriverActivityCheck}
            />
          {:else}
            <svelte:component
              this={truckerActivityTable}
              bind:results={truckerResults}
              bind:currentDriverIndex
              bind:disabledUpdateButton
              {centersMap}
              on:updateDriverActivityCheck={updateDriverActivityCheck}
            />
          {/if}
        </div>
      </div>
      <div id="failedDataComment" style="display: {failedDataCommentDisplay};">
        データ取得に失敗しました。
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .wrapper {
    width: calc(100% - 40px);
    padding: 20px 20px 20px 20px;
    flex-direction: column;
  }

  .titleArea {
    height: 40px;
    display: flex;
  }

  .titleLine {
    background-color: #064491cb;
    min-width: 15px;
    height: 40px;
    border-radius: 0px 0px 0px 0px;
  }

  .title {
    display: flex;
    font-size: x-large;
    margin: auto auto auto 10px;
    min-width: 300px;
  }

  .infoWrapper {
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 8px;
  }

  .businessArea {
    margin: 16px 0px 0px 0px;
    width: calc(50% - 4px);
  }

  .captionArea {
    background-color: #064491cb;
    width: 150px;
    height: 26px;
    border-radius: 10px 10px 0px 0px;
    padding-top: 12px;
    padding-left: 20px;
    color: #fff;
    font-weight: 900;
  }

  .captionTabs {
    display: flex;
    justify-content: start;
  }

  .loadingMessage {
    margin: 10px;
    font-size: smaller;
  }

  .selectConditions {
    background-color: #0645917e;
    width: fit-content;
    min-width: fit-content;
    padding-right: 18px;
    height: 26px;
    border-radius: 10px 10px 0px 0px;
    padding-top: 12px;
    padding-left: 20px;
    color: #fff;
    font-weight: 900;
  }

  .selectConditions:hover {
    filter: brightness(1.2);
  }

  input[name="selectConditions"] {
    display: none;
  }

  .captionTabs input:checked + .selectConditions {
    background-color: #064491cb;
  }

  .mapArea {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    min-width: 340px;
    height: calc(100vh - 230px);
    background-color: white;
    border: 1.5px solid #bdbdbdcb;
    border-radius: 0 5px 5px 5px;
    padding: 5px 5px 5px 5px;

    .noteOnReload {
      text-align: right;
      position: absolute;
      right: 5px;
      top: -31px;
      font-size: 12px;
      line-height: 1.1;

      .material-icons {
        font-size: 18px;
        vertical-align: middle;
        margin-right: -3px;
      }
    }

    .reloadButton {
      position: absolute;
      right: 10px;
      top: 10px;
      background-color: rgba(6, 68, 145, 0.796);
      border: 1px solid rgb(107, 107, 107);
      border-radius: 5px;
      width: 40px;
      height: 40px;
      cursor: pointer;
      padding-top: 5px;
      z-index: 2;
      color: white;
    }

    .reloadButton:hover {
      background-color: rgba(6, 68, 145, 0.9);
    }
  }

  .resultArea {
    box-sizing: border-box;
    width: 100%;
    min-width: 340px;
    max-height: calc(100vh - 230px);
    background-color: white;
    border: 1.5px solid #bdbdbdcb;
    border-radius: 0 5px 5px 5px;
    padding: 5px 5px 5px 5px;
  }

  #failedDataComment {
    margin: 10px;
    font-size: smaller;
  }

  @media screen and (max-width: 768px) {
    .wrapper {
      width: calc(100% - 20px);
      height: calc(100vh - 100px);
      padding: 10px;
    }
    .infoWrapper {
      flex-flow: column;
    }
    .businessArea {
      width: 100%;
      margin-top: 3px;
    }
    .captionArea {
      margin-top: 8px;
      width: 150px;
      height: 22px;
      border-radius: 10px 10px 0px 0px;
      padding-top: 9px;
      padding-left: 20px;
      color: #fff;
      font-weight: 900;
      font-size: 15px;
    }
    .selectConditions {
      padding-right: 18px;
      height: 22px;
      border-radius: 10px 10px 0px 0px;
      padding-top: 9px;
      padding-left: 20px;
      color: #fff;
      font-weight: 900;
      font-size: 15px;
    }
    .mapArea {
      height: calc((100vh - 210px) / 2);
    }
    .resultArea {
      max-height: calc((100vh - 210px) / 2);
    }
  }
</style>
